import { STRING_CONSTANTS } from 'constants/constants';

let config: Config | null = null;
let configPromise: Promise<Config> | null = null;

export interface Config {
  BFF_URL: string;
}

export async function loadConfig(): Promise<Config> {
  // If the config has already been loaded, return it directly
  if (config) {
    return config;
  }

  // If a request is already in progress, return the existing promise
  if (configPromise) {
    return configPromise;
  }

  // Otherwise, initiate the fetch request and store the promise
  configPromise = new Promise<Config>(async (resolve, reject) => {
    try {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      const fetchedConfig: Config = await response.json();
      config = fetchedConfig; // Cache the configuration
      resolve(fetchedConfig); // Resolve the promise with the fetched config
    } catch (error) {
      reject(
        new Error('Could not load configuration. Please try again later.')
      );
    }
  });

  return configPromise; // Return the promise so that subsequent calls wait for the result
}
export const getBaseUrl = (): string => {
  if (STRING_CONSTANTS.HOST_PROXY_URL) {
    // Use HOST_PROXY_URL with APP_ID if running within the host UI
    return `${STRING_CONSTANTS.HOST_PROXY_URL}/${STRING_CONSTANTS.APP_ID}`;
  } else if (config) {
    // If config has been loaded, return BFF_URL from the loaded config
    return config.BFF_URL;
  } else {
    throw new Error('Configuration has not been loaded yet.');
  }
};
