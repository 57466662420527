import axios from 'axios';
import { getBaseUrl } from 'utils/configLoader';

export const fetchCertificationData = async (consignmentId: string) => {
  try {
    const response = await axios.get(
      `${getBaseUrl()}/certification/${consignmentId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching certification data:', error);
    throw error;
  }
};

export const postCertificationData = async (certData: any) => {
  try {
    const response = await axios.post(
      `${getBaseUrl()}/certification`,
      certData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error posting certification data:', error);
    throw error;
  }
};
