/* Scanner page allows user to scan workorder or manually enter workorder
   and allows to select location from dropdown */

// React and Hooks
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Interstate
import { Grid } from '@interstate/components/Grid';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';
import { BarCodeSolidIcon } from '@interstate/components/Icons';
import { NumericInput } from '@interstate/components/NumericInput';
import { SearchOption } from '@interstate/components/SearchInput';
import { Interstitial } from '@interstate/components/Interstitial';

// Styles
import 'components/scanner/Scanner.css';

// Constants
import { LocalStorageKeys, STRING_CONSTANTS } from 'constants/constants';

// Reusable Components
import LocationPicker from 'reusableComponents/locationPicker/LocationPicker';
import ErrorMessageBar from 'reusableComponents/messages/errorMessageBar/ErrorMessageBar';
import ConfirmationModal from 'reusableComponents/confirmationModal/ConfirmationModal';
import RecentScanList from 'reusableComponents/recentScans/RecentScans';

// Redux and Store
import { getRecentScansForUser } from 'store/slice/recentScans/recentScansSlice';
import { RootState, AppDispatch } from 'store/store';
import { fetchLocation } from 'store/slice/locations/locationSlice';
import { fetchVehicle, resetVehicle } from 'store/slice/vehicle/vehicleSlice';

// interstate component
import { MFPayload } from 'models/MFPayload';
import { ScanResult } from 'models/ScanResult';
import { Snackbar } from '@interstate/components/Snackbar';

type ScannerProps = MFPayload & {
  onSubmit: (locationCode: string) => void; // Add onSubmit prop
  successMessage?: string | null; // Callback to receive success message
};

const Scanner: React.FC<ScannerProps> = ({
  onSubmit,
  successMessage,
  ...props
}) => {
  STRING_CONSTANTS.HOST_PROXY_URL = props.appConfig?.proxyUrl ?? '';

  // Using useState hook to manage state for selected location, work order input, and location options
  const [selectedLocation, setSelectedLocation] = useState('');
  const [workOrder, setWorkOrder] = useState('');
  const [showError, setShowError] = useState<string | null>(null);
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [locationCode, setLocationCode] = useState('');

  const dispatch: AppDispatch = useDispatch();

  // 'RootState' is the type for the entire Redux state, and 'state.auctionLocation' accesses the specific slice of state
  const LocationState = useSelector((state: RootState) => state.location);
  const { loading, data, error } = useSelector(
    (state: RootState) => state.vehicle
  );

  const recentScans = useSelector(
    (state: RootState) => state.recentScans?.data?.RecentScans
  );

  const userId = props.userDetails?.userId;

  useEffect(() => {
    document.addEventListener('onScanComplete', ((e: CustomEvent<ScanResult>) =>
      handleOnScanComplete(e.detail)) as EventListener);

    return () => {
      document.removeEventListener('onScanComplete', ((
        e: CustomEvent<ScanResult>
      ) => handleOnScanComplete(e.detail)) as EventListener);
    };
    // eslint-disable-next-line
  }, []);

  const handleOnScanComplete = (event: ScanResult) => {
    setWorkOrder(event.result);
  };

  const handleScan = () => {
    if (props.openScanner) {
      props.openScanner(); // Ensure that this function is called
    } else {
      console.error('openScanner function not provided in props.');
    }
  };

  useEffect(() => {
    dispatch(fetchLocation());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Retrieve saved location from local storage
    const savedLocation = localStorage.getItem(LocalStorageKeys.LOCATION);
    if (savedLocation) {
      setSelectedLocation(savedLocation);

      const parts = savedLocation.split(' - ');
      const code = parts.length > 1 ? parts[1] : '';
      setLocationCode(code);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getRecentScansForUser({ userId: userId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (LocationState.data?.locations) {
      const transformedData = LocationState.data.locations.map((item) => ({
        label: `${item.locationName} - ${item.locationCode}`
      }));
      setOptions(transformedData);
    }
  }, [LocationState?.data]);

  // Handles flow when vehicle data is fetched
  useEffect(() => {
    if (formSubmitted && data) {
      if (data.status === 'CHECKED_OUT') {
        setShowModal(true);
      } else {
        onSubmit(locationCode);
      }
      setFormSubmitted(false);
    }
    // eslint-disable-next-line
  }, [data, formSubmitted, onSubmit, locationCode]);

  useEffect(() => {
    if (error) {
      setShowError(error);
    }
  }, [error]);

  const handleKeyboardType = (event: any) => {
    event.target.setAttribute('inputMode', 'numeric');
  };

  function handleLocationChange(value: string) {
    setSelectedLocation(value);

    // Extract locationCode
    const parts = value.split(' - ');
    const code = parts.length > 1 ? parts[1] : '';
    setLocationCode(code);
    localStorage.setItem(LocalStorageKeys.LOCATION, value);
  }

  function handleWOChange(event: any) {
    const inputValue = event.target.value;
    setWorkOrder(inputValue);
  }

  const submitForm = (loc: string, workOrderNumber: string) => {
    setShowError(null);
    setFormSubmitted(true);
    dispatch(resetVehicle());
    dispatch(fetchVehicle({ location: loc, workOrder: workOrderNumber }));
  };

  const handleModalHideAction = () => {
    setShowModal(false);
  };

  const handleModalContinueAction = () => {
    setShowModal(false);
    onSubmit(locationCode);
  };

  const searchVehicle = (requestData: {
    location: string;
    workOrder: string;
  }) => {
    setLocationCode(requestData.location);
    submitForm(requestData.location, requestData.workOrder);
  };

  return (
    <Box className='scanner-page'>
      {loading && (
        <Interstitial
          data-testid={'spinner'}
          fullScreen={false}
          size={2}
          message={''}
        />
      )}
      {successMessage && (
        <Snackbar
          show={successMessage !== null}
          message={successMessage}
          position='top-center'
          type='success'
        />
      )}
      {showError && <ErrorMessageBar message={showError} />}
      <Grid container spacing={2} className='scanner-grid-container'>
        <Grid xs={12} className='header-container'>
          <Typography variant='h6' className='header-text'>
            {STRING_CONSTANTS.CERT_ASSIST}
          </Typography>
          <Typography variant='h1' className='header-text'>
            {STRING_CONSTANTS.SCAN}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <LocationPicker
            options={options}
            onSelect={handleLocationChange}
            value={selectedLocation}
          />
        </Grid>
        <Grid xs={2} className='barcode-container'>
          <Button block className='golden-button' onClick={handleScan}>
            <BarCodeSolidIcon width={40} height={40} />
          </Button>
        </Grid>

        <Grid xs={10}>
          <NumericInput
            data-testid='scanner_wo_input'
            name='Work Order Number'
            maxLength={7}
            placeholder='Work Order Number'
            value={workOrder}
            autoInsertCommas={false}
            onChange={handleWOChange}
            onFocus={handleKeyboardType}
          />
        </Grid>

        <Grid xs={12}>
          <Button
            data-testid='start_certification_button'
            disabled={
              !(workOrder!.length === 7 && selectedLocation.length !== 0)
            }
            onClick={() => submitForm(locationCode, workOrder)}
            block
            type='submit'
            className='golden-button'
          >
            {STRING_CONSTANTS.START}
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <RecentScanList
          recentScansList={recentScans || []}
          searchVehicle={searchVehicle}
        />
      </Grid>
      <ConfirmationModal
        modalHeader={STRING_CONSTANTS.VEHICLE_SOLD}
        modalBody={STRING_CONSTANTS.VEHICLE_SOLD_DESC}
        confirmationText={STRING_CONSTANTS.CONTINUE}
        cancelText={STRING_CONSTANTS.CANCEL}
        showModal={showModal}
        onContinue={handleModalContinueAction}
        onCancel={handleModalHideAction}
      />
    </Box>
  );
};

export default Scanner;
